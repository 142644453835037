.page-header {
    background-color: var(--color-white);
    position: relative;

    &.-open {
        overflow: scroll;
        height: 100%;
        min-height: 100%;
    }

    @media (--screen-nav) {
        position: absolute;
        left: 0;
        right: 0;

        .container {
            display: flex;
            justify-content: space-between;
        }
    }
}

.page-header__top {
    padding: 1.4rem 0 1.2rem;

    @media (--screen-nav) {
        position: absolute;
        padding: 2rem 0 2.5rem;

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-image: url("../svg/header.svg");
            background-size: cover;
            width: 31.4rem;
            height: 11rem;
        }
    }
}

.page-header__nav {
    display: none;
    background-color: var(--color-white);
    width: 100%;
    height: 100%;
    min-height: calc(100% - 6.2rem);
    padding: 3.2rem 0;
    box-sizing: border-box;

    @media (--screen-nav) {
        display: block;
        background-color: transparent;
        position: relative;
        min-height: auto;
        padding: 0;

        .container {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
        }
    }

    .page-header.-open & {
        display: block;
    }
}

.page-header__nav__list {
    margin: 0 0 2.6rem;

    li {
        margin: 0;

        &::before {
            display: none;
        }
    }

    &.-main,
    &.-meta {
        li:hover, li:focus, li.-active {
            .page-header__link {
                color: var(--color-orange);
            }
        }
    }

    @media (--screen-nav) {
        margin: 0;

        li {
            line-height: 1.2;
        }

        &.-main {
            position: absolute;
            top: 9.2rem;

            li {
                padding: 0 1.9rem;
            }
        }

        &.-meta li {
            padding: 1.2rem 1.5rem 1.4rem;
        }

        &.-main,
        &.-meta {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex-wrap: wrap;
            right: 0;
        }
    }
}

.page-header__link {
    cursor: pointer;
    text-decoration: none;

    .page-header__nav__list.-main & {
        font-weight: var(--font-weight-semiBold);
        font-size: 2.4rem;
        color: var(--color-purple);
        line-height: 1.67;

        @media (--screen-nav) {
            font-size: 1.6rem;
            color: var(--color-white);
            line-height: initial;
        }
    }

    .page-header__nav__list.-meta & {
        font-size: 1.6rem;
        color: var(--color-purple-medium);
        line-height: 1.69;

        @media (--screen-nav) {
            font-size: 1.3rem;
            color: var(--color-purple);
            line-height: inherit;
        }
    }

    &.-search {
        span {
            margin-right: 1rem;
        }

        @media (--screen-nav) {
            span {
                display: none;
            }
        }
    }

    .dropdown.-open & {
        color: var(--color-orange);
    }
}
