%form__field-reset {
    transition: all .2s ease;
    box-sizing: border-box;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1rem;
    vertical-align: baseline;
    background: var(--color-white);
    border: .1rem solid var(--color-purple-medium);

    color: var(--default-text-color);
    font-size: 1.6rem;
    line-height: 1.5;

    // Firefox outline style
    outline: none;
}

.form__field {
    @extend %form__field-reset;
    font-family: var(--font-family);

    &:focus {
        outline: none;
        border: .1rem solid var(--color-purple);
    }

    &.-textarea {
        min-height: 15rem;
        resize: vertical;
    }

    .form__entry.-invalid & {
        border-color: var(--color-red);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }
}

input[type="radio"],
input[type="checkbox"] {
    position: absolute;
    left: 0;
}

