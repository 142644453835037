.social-item {
    position: relative;
    vertical-align: middle;
    font: 0/0 sans-serif;
    display: inline-block;

    background-repeat: no-repeat;
    background-position: center center;

    &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;

        background-color: var(--color-white-10);
        border-radius: 50%;
        width: 4.5rem;
        height: 4.5rem;

        transition: background .3s ease;
    }

    &.-facebook {
        background-image: url("includes/facebook.svg");
        background-size: 1.1rem 2.1rem;
    }

    &.-instagram {
        background-image: url("includes/instagram.svg");
        background-size: 1.8rem 1.8rem;
    }

    &.-linkedin {
        background-image: url("includes/linkedin.svg");
        background-size: 1.8rem 2.1rem;
    }

    &.-youtube {
        background-image: url("includes/youtube.svg");
        background-size: 2.1rem 1.8rem;
    }

    &:hover, &:focus {
        &::before {
            background-color: var(--color-white-50);
        }
    }
}
