.page-item {
    display: block;
    text-decoration: none;
    border-bottom: .1rem solid var(--color-purple-light);
    padding: 2.5rem 0;
    box-sizing: border-box;
    max-width: 60rem;

    .search__pages li:first-of-type & {
        padding-top: 0;
    }

    .search__pages li:last-of-type & {
        border-bottom: none;
    }

    h3 {
        margin-bottom: 1.5rem;

        @media (--screen-medium-large) {
            font-size: 2rem;
        }
    }

    p {
        color: var(--default-text-color);

        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
