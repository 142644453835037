.pagination {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    height: 4rem;
    width: 100%;

    color: var(--color-purple-medium);
    font-size: 1.5rem;

    a {
        color: var(--color-purple-medium);
        text-decoration: none;

        &[href]:hover, &[href]:focus, &[href]:active {
            border-color: var(--color-purple);
            z-index: 1;
        }
    }
}

.pagination__summary {
    width: auto;
    padding: 0 .5rem;
    margin: 0;
    align-self: center;

    text-align: center;
}

.pagination__list {
    display: none;
    align-items: stretch;
    margin: 0;
    padding: 0;

    list-style: none;
}

.pagination__previous, .pagination__next {
    display: flex;
    align-items: center;
    padding: 0 1.3rem;
    background-color: transparent;
    border-radius: 50%;
    transition: all .3s ease;

    &:not([href]) {
        color: var(--color-grayscale-1);
    }

    &:hover, &:focus {
        color: var(--color-purple);
        background-color: var(--color-purple-extra-light);
    }
}

.pagination__previous {
    padding: 0 1.3rem 0 1.2rem;
}

.pagination__next {
    padding: 0 1.2rem 0 1.3rem;
    margin-left: -.1rem;
}

.pagination__item {
    display: flex;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* stylelint-disable selector-class-pattern */
.pagination__item__link, .pagination__item__spacer {
    display: inline-block;
    box-sizing: border-box;
    margin-left: -.1rem;
    width: 4rem;

    text-align: center;
    line-height: 4rem;

    transition: color .3s ease;

    .pagination__item.-current &,
    &:hover, &:focus {
        color: var(--color-purple);
        text-decoration: underline;
    }
}
/* stylelint-enable selector-class-pattern */

@media (--screen-medium-large) {
    .pagination {
        width: auto;
        justify-content: flex-start;
    }

    .pagination__summary {
        display: none;
    }

    .pagination__list {
        display: flex;
    }

    .pagination__previous, .pagination__next {
        span {
            display: none;
        }
    }
}
