.downloads {
    @extend %reset-list;
}

// Single item
.downloads-item {
    display: flex;
    justify-content: space-between;
    padding: .95rem 0;
    border-top: .1rem solid var(--color-purple-borders);
    margin: 0;

    &:last-of-type {
        border-bottom: .1rem solid var(--color-purple-borders);
    }
}

.downloads-item__info {
    width: calc(100% - 5rem);

    @media (--screen-medium-large) {
        width: calc(100% - 6rem);
    }
}

.downloads-item__name {
    font-weight: var(--font-weight-medium);
    font-size: 1.6rem;
    color: var(--color-orange);
    line-height: 1;
    margin: 0;
}

.downloads-item__meta span {
    font-size: 1.3rem;
    color: var(--color-purple-medium);
}

span.downloads-item__meta__extension {
    background: #BABAC5;
    padding: .4rem;
    font-weight: var(--font-weight-bold);
    font-size: 1rem;
    color: var(--color-white);
    margin-right: .6rem;
}

.downloads-item__action {
    display: block;
    width: 4rem;
    height: 4rem;
    box-sizing: border-box;
    text-align: center;

    background-image: url("../svg/download.svg");
    background-repeat: no-repeat;
    background-size: 1.2rem 1.3rem;
    background-position: center center;

    @media (--screen-medium-large) {
        width: 5rem;
        height: 5rem;
        background-size: 1.6rem 1.8rem;
    }
}
