.product-gallery {
    display: flex;
    flex-direction: column;
    max-width: 80.5rem;
    position: relative;
    z-index: 1;

    h1 + & {
        margin-top: 2.2rem;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: -3.3rem;
        left: -1.6rem;
        z-index: -1;
        background-color: var(--color-white);
        width: calc(100% + 3.2rem);
        height: 14.5rem;
    }

    @media (--screen-medium-large) {
        &::after {
            left: -2rem;
            width: calc(100% + 4rem);
            height: 18rem;
        }
    }

    @media (--screen-large) {
        width: 45%;
        position: absolute;
        top: 10.7rem;
        right: 0;

        h1 + & {
            margin-top: 0;
        }

        &::after {
            display: none;
        }
    }

    @media (--screen-nav) {
        top: 16.7rem;
    }
}

.product-gallery__top {
    margin-bottom: 1.2rem;

    a {
        display: block;
        pointer-events: none;
    }

    img {
        display: block;
        width: 100%;
        max-height: 52rem;
    }

    .video {
        margin: 0;
    }
}

.product-gallery__bottom {
    @extend %reset-list;
    display: flex;
    width: calc(100% + 1.2rem);
}

.product-gallery__item {
    display: inline-block;
    width: calc(100% / 3 - 1.2rem);
    height: 7.4rem;
    margin-right: 1.2rem;

    img {
        @extend .image;
    }

    @media (--screen-medium-large) {
        height: 17.3rem;
    }

    &.-third-element {
        position: relative;

        .product-gallery__item__link::before {
            display: none;
        }

        .product-gallery__item__link::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            transition: none;
            opacity: 1;
            background-color: var(--color-purple-80);
            width: 100%;
            height: 100%;
        }
    }
}

.product-gallery__item__link {
    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: url("../svg/show.svg") no-repeat center;
        background-size: 2.2rem 1.5rem;
        width: 2.2rem;
        height: 1.5rem;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        background-color: var(--color-orange-80);
        width: 100%;
        height: 100%;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
        }
    }
}

.product-gallery__item__count {
    font-weight: var(--font-weight-medium);
    font-size: 2.8rem;
    color: var(--color-white);
    text-decoration: none;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}
