.page-footer {
    flex-shrink: 0;
    display: block;
    width: 100%;
    box-sizing: border-box;
    background-color: var(--color-purple);
    background-image: radial-gradient(circle at 99% 193%, rgba(255, 103, 22, .2) 0%, rgba(255, 103, 22, 0) 100%);
    overflow-x: hidden;
    font-size: 1.5rem;

    @media (min-width: 1500px) {
        overflow-x: visible;
    }
}
