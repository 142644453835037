.head-office {
    line-height: 1.6;

    a {
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    @media (--screen-medium-large) {
        margin-bottom: 5rem;
    }
}

h3.head-office__title {
    font-weight: var(--font-weight-semiBold);
    color: var(--color-purple);
    margin: 0 0 .2rem;
}

.head-office__address {
    margin: 0;
}

.head-office__phone {
    color: inherit;
}
