@import '~@inventis/cookieconsent/src/cookie-consent.scss';

:root {
    --cookie-consent-options-background-color: var(--color-purple-box);
}

.cookie-consent__option-description {
    position: relative;
    top: -1em;
}

.cookie-consent {
    box-shadow: 0 0 0 .1rem var(--color-purple-medium);

    .form__label {
        font-weight: bold;
    }
}
