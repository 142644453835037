.form__message {
    display: block;
    margin-top: .3rem;

    color: var(--color-red);
    font-size: 1.3rem;
    line-height: 1.4;

    .form__entry.-checkbox & {
        margin-bottom: 1rem;
    }
}
