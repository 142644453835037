.row {
    margin: 0;
    padding: 0 2rem;

    &[data-block-type=video] {
        background-color: var(--color-purple-extra-light);
        overflow: hidden;
    }
}

.row__sizer {
    width: 114rem;
    max-width: 100%;
    margin: 0 auto;
}

.row__inner {
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;

    @media (min-width: 37.5rem) {
        width: 60rem; // Don't now why this is needed but will keep it to be sure
    }

    .pagecontent-row-container.-align-left &, .row.-align-left & {
        margin-left: 0;
    }

    .row.-size-wide & {
        max-width: 103.9rem;
        width: auto;
    }

    .row[data-block-type="image-with-text"] &,
    .row[data-block-type="image-with-text-reversed"] &,
    .row[data-block-type="call-to-action-extra"] & {
        max-width: none;
    }
}
