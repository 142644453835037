.form__label {
    display: block;
    margin-bottom: .5rem;
    transition: all .2s ease;

    &.-radio,
    &.-checkbox {
        padding: 0 0 0 2.6rem;
        opacity: 1;
    }

    &.-form_entity {
        display: none; // temporary fix to hide (double) form title inside label field; update after mortar update
    }
}
