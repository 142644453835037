@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x           : 1rem !default;
$hamburger-padding-y           : 1rem !default;
$hamburger-layer-width         : 2rem !default;
$hamburger-layer-height        : .2rem !default;
$hamburger-layer-spacing       : .4rem !default;
$hamburger-layer-color         : var(--color-orange) !default;
$hamburger-layer-border-radius : 0 !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(50%) !default;
$hamburger-active-hover-filter: $hamburger-hover-filter !default;

// Hamburger
// ==================================================
.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    outline: none;
    position: absolute;
    right: .6rem;
    top: .9rem;

    @media (--screen-medium-large) {
        top: 1.9rem;
    }

    @media (--screen-nav) {
        display: none;
    }
}

.hamburger__box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger__box-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition: transform .15s ease;
    }

    &::before,
    &::after {
        content: "";
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

.hamburger {
    .hamburger__box-inner {
        top: auto;
        bottom: 0;
        transition-duration: 0.13s;
        transition-delay: 0.13s;
        transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

        &::after {
            top: ($hamburger-layer-spacing * 2 + $hamburger-layer-height * 2) * -1;
            transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            opacity 0.1s linear;
        }

        &::before {
            transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }

    &.-active {
        .hamburger__box-inner {
            transform: translate3d(0, ($hamburger-layer-spacing + $hamburger-layer-height) * -1, 0) rotate(-45deg);
            transition-delay: 0.22s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

            &::after {
                top: 0;
                opacity: 0;
                transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                opacity 0.1s 0.22s linear;
            }

            &::before {
                top: 0;
                transform: rotate(-90deg);
                transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
                transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}
