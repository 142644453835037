@import '~glightbox/dist/css/glightbox.min.css';
.gslide-external {
    background: transparent;

    img {
        @extend .image;
    }
}

.gallery {
    @extend %reset-list;
    padding-bottom: 4rem !important;

    @media (--screen-small) {
        column-count: 2;
        gap: .8rem;
    }

    @media (--screen-medium-large) {
        column-count: 3;
    }
}

.gallery__item {
    display: block;
    width: 100%;
    height: 100%;
    margin: .8rem 0;
    box-sizing: border-box;

    &:first-child {
        margin-top: 0;
    }

    img {
        @extend .image;
    }
}

.gallery__item__link {
    display: block;
    position: relative;

    text-decoration: none;

    &::before {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background: url("../svg/show.svg") no-repeat center;
        background-size: 2.2rem 1.5rem;
        width: 2.2rem;
        height: 1.5rem;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;

        background: var(--color-orange-80);
        width: 100%;
        height: 100%;

        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    &:hover,
    &:focus {
        &::before,
        &::after {
            opacity: 1;
        }
    }
}

.gallery__image {
    @extend .image;
}
