.logo {
    position: relative;
    vertical-align: middle;
    font: 0/0 sans-serif;
    display: inline-block;

    color: var(--color-general);

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .page-header & {
        width: 9rem;
        height: 3.6rem;
        z-index: 1;

        @media (--screen-medium-large) {
            width: 15rem;
            height: 5.3rem;
        }
    }

    .page-footer & {
        display: block;
        margin-bottom: 2.5rem;
        width: 12.3rem;
        height: 4rem;
    }
}
