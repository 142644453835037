.search__container {
    max-width: 60rem;
    display: flex;
    align-items: center;

    input {
        border-right: none;
        height: 5rem;
    }
}

.search__button {
    max-width: 5rem;
    max-height: 5rem;
}

.search__results {
    margin-top: 2rem;
}

.search__pages {
    @extend %reset-list;
}
