.form__alert {
    padding: 1rem;
    margin-bottom: 3rem;

    &.-success {
        background-color: #E7F1D5;
        border: .1rem solid #7CA13B;
        color: #7CA13B;
    }

    &.-error {
        background-color: var(--color-red-light);
        border: .1rem solid var(--color-red);
        color: var(--color-red);
    }
}
