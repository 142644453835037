.page-head {
    @extend .wrapper.-purple-gradient;
    background-size: cover !important;
    padding: 2.7rem 0 3.3rem;
    margin-bottom: 2rem;

    h1 {
        margin: 0;
    }

    &.-textpage {
        text-align: center;

        h1 {
            margin: 0 auto;
        }
    }

    @media (--screen-large) {
        margin-bottom: 4.8rem;

        h1 {
            max-width: calc(100% - 25rem);
        }

        &.-with-image h1 {
            max-width: 50%;
        }
    }

    @media (--screen-nav) {
        padding: 16.7rem 0 3.3rem;

        &.-search {
            padding: 16.7rem 0 5rem;
        }
    }
}
