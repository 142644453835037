.icon {
    position: relative;
    vertical-align: middle;
    font: 0/0 sans-serif;
    display: inline-block;

    &::before {
        content: '';
        transition: transform .3s ease;

        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;

        vertical-align: middle;

        background-color: var(--color-purple);
        background-size: 1.8rem 1.8rem;

        @supports (mask-repeat: no-repeat) {
            background: transparent;
            mask-repeat: no-repeat;
            mask-size: 1.8rem 1.8rem;
        }
    }

    // arrow
    &.-arrow::before {
        margin: -.2rem 1rem 0 0;
        background-image: url("../svg/arrow.svg");
        background-size: .8rem 1rem;
        width: .8rem;
        height: 1rem;
    }

    &.-arrow-down::before {
        margin-left: 2rem;
        background-image: url("../svg/arrow-down.svg");
        background-size: 1rem .8rem;
        width: 1rem;
        height: .8rem;

        .dropdown.-open & {
            transform: rotate(180deg);
        }
    }

    &.-arrow-select::before {
        margin-left: 2rem;
        background-image: url("../svg/select.svg");
        background-size: .9rem .5rem;
        width: .9rem;
        height: .5rem;

        .dropdown__trigger:hover &, .dropdown__trigger:focus & {
            @supports (mask-repeat: no-repeat) {
                background: var(--color-orange);
                mask-image: url("../svg/select.svg");
                mask-size: .9rem .5rem;
            }
        }

        .dropdown.-open & {
            transform: rotate(180deg);
        }
    }

    // search
    &.-search {
        &::before {
            background-image: url("../svg/search.svg");
            background-size: 1.9rem 1.9rem;
            width: 1.9rem;
            height: 1.9rem;
        }

        &.-white::before {
            @supports (mask-repeat: no-repeat) {
                background: var(--color-white);
                mask-image: url("../svg/search.svg");
                mask-size: 1.9rem 1.9rem;
            }
        }
    }

    // phone
    &.-phone {
        background-image: url("../svg/phone.svg");
        margin-right: 1.1rem;
    }

    // email
    &.-email {
        background-image: url("../svg/email.svg");
        background-size: 2rem 1.6rem;
        width: 2rem;
        height: 1.6rem;
        margin-right: 1rem;
    }

    // flag
    &.-flag {
        &::before {
            background-repeat: no-repeat;
            background-size: 1.6rem 1.06rem;
            width: 1.6rem;
            height: 1.06rem;
            margin-top: -.2rem;
            margin-right: .9rem;
        }

        &.-nl_be::before,
        &.-fr_be::before,
        &.-belgium::before {
            background-image: url("../svg/flags/flag-be.svg");
        }

        &.-fr_fr::before,
        &.-france::before {
            background-image: url("../svg/flags/flag-fr.svg");
        }

        &.-de_de::before,
        &.-germany::before {
            background-image: url("../svg/flags/flag-de.svg");
        }

        &.-sv_se::before,
        &.-sweden::before {
            background-image: url("../svg/flags/flag-se.svg");
        }

        &.-en_gb::before,
        &.-united-kingdom::before {
            background-image: url("../svg/flags/flag-gb.svg");
        }

        &.-nl_nl::before,
        &.-netherlands::before {
            background-image: url("../svg/flags/flag-nl.svg");
        }

        &.-lu_lu::before,
        &.-luxembourg::before {
            background-image: url("../svg/flags/flag-lu.svg");
        }

        &.-en_us::before,
        &.-united-states::before {
            background-image: url("../svg/flags/flag-usa.svg");
        }
    }
}
