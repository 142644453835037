.form__checkbox {
    display: block;
    opacity: 0;
    height: 0;

    + .form__label {
        position: relative;
        padding-left: 2.6rem;

        &::before,
        &::after {
            content: '';

            display: block;
            width: 1.6rem;
            height: 1.6rem;

            position: absolute;
            top: .3rem;
            left: 0;
        }

        &::before {
            transition:
                box-shadow .2s ease,
                background-color .2s ease;
            box-shadow: inset 0 0 0 .1rem var(--color-purple-medium);
            background-color: var(--color-white);
        }

        &::after {
            transition:
                opacity .3s ease,
                transform .2s ease;

            color: var(--color-purple);
            z-index: 1;
            opacity: 0;
            transform: scale(0);

            background-image: url(check.svg);
            background-repeat: no-repeat;
            background-size: 1.2rem 1rem;
            background-position: center center;
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .1rem var(--color-purple);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled + .form__label::before {
        background-color: transparent;
    }

    &:disabled + .form__label::before, &:disabled + .form__label::after {
        opacity: .4;
    }
}
