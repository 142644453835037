.row[data-block-type="image-with-text"],
.row[data-block-type="image-with-text-reversed"] {
    padding: 0;

    & > .row__sizer,
    & > .row__inner {
        width: 100%;
        max-width: none;
    }
}

.image-with-text {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-top: .1rem solid var(--color-purple-borders);
    justify-content: center;

    &:last-of-type {
        border-bottom: .1rem solid var(--color-purple-borders);
    }

    p:last-of-type {
        margin-bottom: 0;
    }

    p:first-of-type {
        margin-top: 0;
    }

    &.-reverse {
        .image-with-text__image {
            order: 1;
        }

        .image-with-text__text {
            order: 0;
        }
    }
}

.image-with-text__text {
    padding: 2rem 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    max-width: 62rem;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    flex: 0 0 calc(50%);

    .-image-text-odd & {
        @media (min-width: 30rem) {
            flex-basis: 75%;
        }
    }

    .-size-wide & {
        padding: 4rem 2rem;
        max-width: none;
    }
}

.image-with-text__text-inner {
    position: relative;
    order: 1;
    width: 100%;
    max-width: 50rem;
    margin: 0;
}

.image-with-text__image {
    .image {
        height: 100%;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        max-height: 63.3rem;
        object-fit: cover;
    }

    flex: 0 0 calc(50%);

    .-image-text-odd & {
        @media (min-width: 30rem) {
            flex-basis: 25%;
        }
    }
}

@media (max-width: 40rem) {
    .-size-wide {
        .image-with-text {
            flex-direction: column;

            &.-reverse {
                .image-with-text__image {
                    order: 0;
                }

                .image-with-text__text {
                    order: 1;
                }
            }
        }

        .image-with-text__text {
            margin: 2rem 0;
            flex-direction: column;
        }
    }
}
