.page-footer__top {
    position: relative;
    padding: 8rem 0 7rem;
    color: var(--color-purple-light);

    .container {
        position: relative;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 0 1rem;
        max-width: 120.6rem;

        @media (--screen-medium-large) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            grid-template-areas:
                    ". ."
                    ". .";
        }

        @media (--screen-large) {
            grid-template-columns: repeat(4, 1fr);
            grid-template-rows: repeat(1, 1fr);
            grid-template-areas: ". . . .";

        }
    }

    .container::after {
        content: '';
        position: absolute;
        bottom: -7rem;
        right: -8rem;
        background-image: url("../svg/footer-line.svg");
        width: 16.9rem;
        height: 48.3rem;
    }
}

.page-footer__top__section {
    margin-bottom: 5rem;

    .title-5 {
        display: block;
        color: var(--color-white);
        font-weight: var(--font-weight-medium);
        margin-bottom: 2.7rem;

        & + .page-footer__social-items {
            margin-top: -1rem;
        }
    }

    ul {
        @extend %reset-list;
    }
}

.page-footer__contact__country,
.page-footer__contact__tel {
    color: var(--color-white);
}

.page-footer__contact__tel {
    display: block;
    margin-top: 2rem;
    cursor: pointer;
    text-decoration: none;

    &::before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        background-image: url("../svg/phone.svg");
        background-size: 1.6rem 1.6rem;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .8rem;
    }

    @media (--screen-medium-large) {
        margin-top: 4rem;
    }

    &:hover, &:focus {
        text-decoration: underline;
    }
}

.page-footer__link {
    a {
        display: flex;
        align-items: center;
        color: var(--color-purple-light);
        line-height: 2;
        text-decoration: none;
        transition: all .3s ease;

        &::before {
            content: '';
            display: inline-block;
            margin: 0 1rem 0 -1rem;
            background-color: var(--color-orange);
            width: 0;
            height: .1rem;
            transition: all .3s ease;
        }
    }

    &:hover, &:focus, &.-active {
        a {
            color: var(--color-white);

            &::before {
                width: 1rem;
            }
        }
    }
}

.page-footer__social-items {
    li {
        display: inline-block;
        margin: 0 .8rem .8rem 0;

        &:last-of-type {
            margin-right: 0;
        }
    }
}
