.entry-points {
    @extend %reset-list;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 4rem 0 !important;

    li {
        margin: 0;
    }

    @media (--screen-large) {
        padding: 14rem 0 17rem !important;
        justify-content: flex-start;
    }

    .intro + & {
        padding: 3.2rem 0 0 !important;
    }
}

//Single item
.entry-point {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    min-width: 25rem;
    min-height: 100%;
    padding: 4.5rem 2.5rem 6.7rem;
    text-align: center;
    text-decoration: none;
    line-height: 1.3;

    background-color: var(--color-purple);
    border: .1rem solid var(--color-purple-dark);

    transition: all .3s ease;

    .button {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 5rem;
        height: 5rem;
    }

    @media (--screen-small-large) {
        width: 31rem;
    }

    &:hover, &:focus {
        background-color: var(--color-purple-hover);

        .button {
            background-color: var(--color-orange-hover);
        }
    }

}

.entry-point__image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto .6rem;
    width: 12.8rem;
    height: 12.8rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: var(--color-purple-images);

    img {
        @extend .image;
    }

    .icon.-flag {
        width: 5rem;
        height: 3.34rem;
        margin: 0 auto;

        &::before {
            background-size: 5rem 3.34rem;
            width: 5rem;
            height: 3.34rem;

            @supports (mask-repeat: no-repeat) {
                mask-size: 5rem 3.34rem;
            }
        }
    }

}

.entry-point__title {
    display: block;
    font-weight: var(--font-weight-medium);
    font-size: 2rem;
    color: var(--color-white);
    text-align: center;
    margin-bottom: 1.1rem;
}

.entry-point__content {
    font-size: 1.5rem;
    color: var(--color-purple-light);
    text-align: center;
    margin: 0;
}
