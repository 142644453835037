.form__entry {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.4rem;
    vertical-align: top;

    @media (--screen-medium-large) {
        max-width: 52rem;
    }

    &.-small {
        max-width: 25rem;
        margin-right: 2rem;

        & + & {
            margin-right: 0;
        }

        @media (--screen-medium-large) {
            display: inline-block;
        }
    }

    & > div:not([class]) {
        order: 2;
    }

    &:not(.-checkbox) {
        .form__label {
            order: 1;
        }

        .form__field,
        .form__select,
        .form__fieldset {
            order: 2;
        }

        .form__message {
            order: 3;
        }
    }
}
