.button {
    display: inline-block;
    margin: 0;
    padding: 1rem 1.6rem;
    background-color: var(--color-orange);
    border: none;
    cursor: pointer;

    font-weight: var(--font-weight-semiBold);
    font-size: 1.2rem;
    color:var(--color-white);
    letter-spacing: .04rem;
    line-height: 2.5;
    text-decoration: none;
    text-transform: uppercase;

    transition: all .3s ease;

    &:hover, &:focus {
        background-color: var(--color-orange-hover);
    }

    &.-arrow {
        position: relative;
        padding: 1rem 3.4rem 1rem 1.6rem;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            right: 1.6rem;
            transform: translateY(-50%);
            background-image: url("../svg/arrow.svg");
            background-repeat: no-repeat;
            background-size: .78rem 1rem;
            background-position: center center;
            width: .78rem;
            height: 1rem;
            transition: all .3s ease;
        }

        &:hover, &:focus {
            &::after {
                right: 1.1rem;
            }
        }
    }

    &.-small-arrow {
        width: 5rem;
        height: 5rem;
        box-sizing: border-box;

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-image: url("../svg/arrow.svg");
            background-repeat: no-repeat;
            background-size: .78rem 1rem;
            background-position: center center;
            width: .78rem;
            height: 1rem;
            transition: all .3s ease;
        }
    }

    &.-outline {
        background-color: transparent;
        color: var(--color-purple);
        border: .1rem solid var(--color-purple-light);
        box-sizing: border-box;

        &:hover, &:focus {
            background-color: var(--color-purple-light);
            color: var(--color-white);
        }
    }
}
