.breadcrumb {
    display: block;
    width: 100%;
    margin-bottom: 1rem;

    font-size: 1.5rem;
    color: var(--color-purple-light);
    text-decoration: none;
    transition: color .3s ease;
    cursor: pointer;

    .icon.-arrow::before {
        transition: margin .3s ease;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-orange);
            mask-image: url("../svg/arrow.svg");
            mask-size: .8rem 1rem;
            transform: rotate(180deg);
        }
    }

    &:hover, &:focus {
        color: var(--color-white);

        .icon.-arrow::before {
            margin: -.2rem 1.5rem 0 -.5rem;
        }
    }
}
