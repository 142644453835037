body {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-width: 32rem;
    min-height: 100dvh;
    margin: 0 auto;

    background-color: var(--color-white);
    word-wrap: break-word; // Don't let long words break the layout on small area's

    font-family: var(--font-family);
    font-size: 1.5rem;
    line-height: 1.56;
    color: var(--default-text-color);

    &.-no-overflow {
        position: fixed;
        overflow: hidden;
        height: 100%;

        /* Prevent iOS safari from still showing main content */
        @supports (-webkit-overflow-scrolling: touch) {
            main {
                opacity: 0;
            }
        }
    }
}

@media (--screen-medium-large) {
    body {
        font-size: 1.6rem;
    }
}
