main {
    flex: 1 0 auto; // used for sticky footer using flexbox
    overflow: hidden;

    & > :last-child {
        padding-bottom: 4rem;

        @media (--screen-medium-large) {
            padding-bottom: 8.6rem;
        }
    }

    &.-detailpage {
        @media (--screen-large) {
            min-height: 80rem;
        }

        @media (--screen-nav) {
            min-height: 100rem;
        }

        @media (min-width: 1500px) {
            min-height: 120rem;
        }
    }
}

.h-hide {
    display: none !important;
}
