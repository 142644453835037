.cards {
    @extend %reset-list;
    display: flex;
    flex-wrap: wrap;
    transition: all .3s ease;

    @media (--screen-medium-large) {
        width: calc(100% + 4rem);
    }

    li {
        display: inline-block;
        width: 100%;

        @media (--screen-medium-large) {
            width: calc(50% - 4rem);
            margin-right: 4rem;
        }

        @media (--screen-large) {
            width: calc(33.33% - 4rem);
        }
    }

    .title-1 + & {
        margin-top: 3rem;

        @media (--screen-medium-large) {
            margin-top: 5rem;
        }
    }

    &.-vacancies {
        width: 100%;
        display: block;
        padding: 5.6rem 0 0 !important;
    }
}

//item
.card {
    display: block;
    text-decoration: none;
    line-height: 1.5;
    margin-bottom: 4rem;

    &:hover, &:focus {
        .card__image::after {
            opacity: 1;
        }

        .card__title {
            color: var(--color-orange);

            &::after {
                right: -.5rem;
            }
        }
    }

    &.-home {
        margin: 0 0 4rem;
    }

    &.-vacancy {
        width: 100%;
        max-width: 38.6rem;

        &:hover, &:focus {
            .card__details::before {
                background-color: var(--color-purple-dark);
            }
        }
    }
}

.card__image {
    position: relative;
    max-height: 20.8rem;

    img {
        display: block;
        width: 100%;
        height: auto;
        max-height: 20.8rem;
        object-fit: cover;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-right-width: 0rem;
        border-left-width: 2.6rem;
        border-bottom: 2.6rem solid var(--color-white);
    }

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(28, 26, 62, .3);
        opacity: 0;
        transition: all .3s ease;
    }

    .card.-home &::before {
        display: none;
    }

    .card.-vacancy & {
        display: none;
    }
}

.card__details {
    display: block;
    background: var(--color-white);
    padding: 1.4rem 2rem 2.7rem 0;
    box-sizing: border-box;
    position: relative;
    transition: all .3s ease;

    &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border: 0 solid transparent;
        border-right-width: 0rem;
        border-left-width: 3.2rem;
        border-bottom: 3.2rem solid var(--color-white);

        .wrapper.-gray & {
            border-bottom: 3.2rem solid var(--color-purple-extra-light);
        }
    }

    .card.-home & {
        padding: 1.4rem 2rem 2.7rem;
    }

    .card.-vacancy & {
        background-color: var(--color-purple-dark);
        padding: 1.5rem 2rem 2rem;
        clip-path: polygon(100% 0, 100% 75%, 94% 100%, 0 100%, 0 0);

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: .1rem;
            left: .1rem;
            width: calc(100% - .2rem);
            height: calc(100% - .2rem);
            background-color: var(--color-purple);
            clip-path: polygon(100% 0, 100% 75%, 94% 100%, 0 100%, 0 0);
        }

        &::after {
            display: none;
        }
    }
}

.card__subtitle {
    display: block;
    font-weight: var(--font-weight-medium);
    font-size: 1.2rem;
    color: var(--color-orange);
    margin-bottom: -.3rem;
}

.card__title {
    display: block;
    position: relative;
    padding-right: 2rem;
    font-weight: var(--font-weight-medium);
    font-size: 2rem;
    color: var(--color-purple);
    margin-bottom: 1.1rem;
    transition: all .3s ease;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);

        background-image: url("../svg/arrow.svg");
        background-repeat: no-repeat;
        background-size: .78rem 1rem;
        background-position: center center;
        width: .78rem;
        height: 1rem;
        transition: all .3s ease;

        @supports (mask-repeat: no-repeat) {
            background: var(--color-orange);
            mask-image: url("../svg/arrow.svg");
            mask-repeat: no-repeat;
            mask-size: .78rem 1rem;
            mask-position: center center;
        }
    }

    .card.-vacancy & {
        color: var(--color-white);
        margin: 0;
    }
}

.card__content {
    display: block;
    font-size: 1.5rem;
    color: var(--color-purple-medium);

    p {
        margin: 0;
    }
}
