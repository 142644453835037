a, a:not([class]) {
    color: var(--color-orange);
    text-decoration: underline;
    cursor: pointer;

    &:hover, &:focus {
        text-decoration: none;
    }

    .page-footer__bottom & {
        color: var(--color-white);
        text-decoration: none;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}
