%reset-list {
    margin: 0;
    padding: 0;
    list-style: none;

    li::before {
        display: none;
    }
}

ul {
    margin: 1rem 0 3.2rem;
    padding: 0 0 0 1.7rem;
    list-style: none;
    line-height: 1.67;

    li {
        margin: 0 0 .4rem;
        position: relative;

        &::before {
            position: absolute;
            left: -1.7rem;
            top: calc(.83em - .25rem);
            content: '';
            width: .5rem;
            height: .5rem;
            background-color: var(--color-orange);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    li > & {
        margin: .8rem 0 0;
    }
}

li > ul > li {
    &::before {
        border-radius: 50%;
        background-color: var(--color-gray-light);
    }
}

/* stylelint-disable selector-max-type */
li > ul > li > ul > li {
    &::before {
        border-radius: 0;
        background-color: var(--color-orange);
    }
}
/* stylelint-enable selector-max-type */
