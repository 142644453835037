.form__select {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        right: 1.2rem;
        transform: translateY(-50%);
        background-image: url("../svg/select.svg");
        background-size: .9rem .5rem;
        width: .9rem;
        height: .5rem;
    }

    select {
        @extend %form__field-reset;
    }
}
