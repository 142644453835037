figure[class="table"] {
    margin: 0;
}

table {
    table-layout: fixed;
    width: 100%;
    margin: 0 0 3.2rem;
    border-collapse: collapse;
    color: var(--default-text-color);

    font-size: 1.3rem;
    line-height: 1.4;

    td, th {
        padding: .8rem .7rem;
        border: .1rem solid var(--color-purple-borders);
        vertical-align: top;
    }

    thead {
        font-weight: bold;
        background-color: var(--color-purple-extra-light);
    }

    th {
        font-weight: var(--font-weight-normal);
        color: var(--color-purple);
        background-color: var(--color-purple-extra-light);
        text-align: left;
    }

    .wrapper.-text > &.-horizontal + .form-wrapper {
        margin-top: 4rem;

        @media (--screen-medium-large) {
            margin-top: 5.6rem;
        }
    }
}
