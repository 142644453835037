.video {
    width: 100%;
    height: 0;
    padding-bottom: calc(100% * (9 / 16));
    margin: 1rem 0 3.2rem;

    position: relative;

    .row &{
        margin: 5rem 0;
    }
}

.video__frame {
    width: 100%;
    height: 100%;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    background-image: url('./video-placeholder.svg');
    background-size: 100%;
    background-position: center;
}
