.container {
    width: 100%;
    max-width: 128rem;
    margin: 0 auto;
    padding: 0 1.6rem;
    box-sizing: border-box;

    &.-relative {
        position: relative;
    }

    @media (--screen-medium-large) {
        padding: 0 2rem;
    }

    &.-col-2 {
        @media (--screen-large) {
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 4rem;
        }
    }

    &.-search {
        margin-top: -4rem;

        @media (--screen-large) {
            margin-top: -7rem;
        }
    }
}
