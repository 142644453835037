.card.-small {
    display: flex;
    margin-bottom: 2rem;

    .card__image {
        display: inline-block;
        width: 100%;
        max-width: 11.9rem;
        max-height: 8.7rem;

        img {
            height: 100%;
        }

        &::before {
            display: none;
        }
    }

    .card__content {
        padding: 1.5rem 2rem 2rem;
        position: relative;
        z-index: 1;
        width: 100%;
        background-color: var(--color-purple-borders);
        clip-path: polygon(100% 0, 100% 75%, 94% 100%, 0 100%, 0 0);

        &::before {
            content: '';
            display: block;
            position: absolute;
            top: .1rem;
            left: .1rem;
            z-index: -1;
            width: calc(100% - .2rem);
            height: calc(100% - .2rem);
            background-color: var(--color-white);
            clip-path: polygon(100% 0, 100% 75%, 94% 100%, 0 100%, 0 0);
            transition: background-color .3s ease;
        }
    }

    .card__title {
        margin-bottom: 0;
    }

    &:hover, &:focus {
        .card__content {
            background-color: var(--color-purple-extra-light);
        }

        .card__content::before {
            background-color: var(--color-purple-extra-light);
        }
    }
}
