.call-to-action {
    margin: 1rem 0 3.2rem;
    padding: 2.6rem 2.4rem 3.2rem;
    background-color: var(--color-purple-box);
}

.call-to-action__title {
    margin-top: 0;
}

.call-to-action__text {
    :first-child {
        margin-top: 0;
    }

    :last-child {
        margin-bottom: 0;
    }
}

.call-to-action__actions {
    margin-top: 2.4rem;
}

@media (--screen-medium-large) {
    .call-to-action {
        padding: 3.2rem 3.2rem 3.9rem;
    }
}
