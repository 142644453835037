.call-to-action-extra {
    display: flex;
    gap: 3rem;
    margin: 3.2rem 0;
    flex-wrap: wrap;
    align-items: flex-start;

    .call-to-action {
        margin: 0;
        flex: 1 0 50rem;
        max-width: 100%;
        box-sizing: border-box;
    }
}

.call-to-action-extra__text {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    flex: 1 0 50%;
    max-width: 64rem;
    margin-inline: auto;

    .image {
        width: auto ;
        height: auto;
        flex: 1 0 auto;
        text-align: center;

        img {
            width: 21rem;
            height: 21rem;
            object-fit: contain;
            display: block;
            margin-inline: auto;
        }
    }

    > p {
        flex: 1 0 30rem;
    }
}

.row[data-block-type="call-to-action-extra"] {
    max-width: 128rem;
    margin-inline: auto;
    box-sizing: border-box;

    .row__sizer {
        width: 100%;
    }
}
