.branches__title {
    position: relative;

    &::after {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100vw;
        height: .1rem;
        background-color: var(--color-purple-borders);
        margin-left: 1.9rem;
    }

}

// Single item
.branch {
    display: inline-block;
    width: 100%;
    margin-bottom: 4rem;

    border: .1rem solid var(--color-purple-borders);
    padding: 1.4rem 1.6rem 3.5rem;
    box-sizing: border-box;

    position: relative;

    &::before {
        content: '';
        display: block;
        position: absolute;
        right: -1.4rem;
        bottom: -1.4rem;
        z-index: 1;
        width: 2.8rem;
        height: 2.8rem;
        background-color: var(--color-white);
        border-top: .1rem solid var(--color-purple-borders);
        transform: rotate(-45deg);
    }
}

.branch__title {
    color: var(--color-purple);
    margin-bottom: 1rem ;
}

.branch__text {
    color: var(--color-purple-medium);
    margin: 0 0 1.8rem;
}

.branch__contact {
    display: block;
    color: var(--color-purple);
    text-decoration: none;

    & + & {
        margin-top: .5rem;
    }
}
