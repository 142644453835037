.form__radio {
    display: none;
    border: 0;

    + .form__label {
        position: relative;

        &::before,
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: .5rem;
            left: 0;

            width: 1.6rem;
            height: 1.6rem;
            border-radius: 50%;
        }

        &::before {
            transition: box-shadow .2s ease, background-color .2s ease;
            box-shadow: inset 0 0 0 .1rem var(--color-purple-medium);
            background-color: var(--color-white);
        }

        &::after {
            top: .8rem;
            left: .3rem;
            z-index: 1;
            opacity: 0;
            transform: scale(0);

            transition: opacity .3s ease, transform .2s ease;

            background-color: var(--color-purple);
            background-repeat: no-repeat;
            background-size: 1rem 1rem;
            background-position: center center;
            width: 1rem;
            height: 1rem;
        }
    }

    &:checked + .form__label::before {
        box-shadow: inset 0 0 0 .1rem var(--color-purple);
    }

    &:checked + .form__label::after {
        opacity: 1;
        transform: scale(1);
    }

    &:disabled + .form__label::before {
        background-color: transparent;
    }

    &:disabled + .form__label::before, &:disabled + .form__label::after {
        opacity: .4;
    }
}
