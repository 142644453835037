.wrapper {
    &.-purple {
        background-color: var(--color-purple);
    }

    &.-purple-gradient {
        background: var(--color-purple);
        background-image: url("../svg/jumbotron.svg");
        background-repeat: no-repeat;
        background-size: contain;

        @media (--screen-medium-large) {
            background-size: cover;
        }
    }

    &.-gray {
        background-color: var(--color-purple-extra-light);
    }

    &.-highlights {
        padding: 4rem 0;
        overflow: hidden;

        @media (--screen-medium-large) {
            padding: 5.9rem 0;
        }
    }

    &.-testimonials {
        padding: 4rem 0;

        @media (--screen-medium-large) {
            padding: 9.5rem 0 8rem;
        }
    }

    &.-vacancies {
        padding: 5rem 0 7.5rem;

        @media (--screen-medium-large) {
            padding: 10rem 0 13.5rem;
        }
    }

    &.-downloads {
        margin: 6.5rem 0 14rem;
    }

    &.-text {
        max-width: 60rem;
        margin: 0 0 4rem;

        @media (--screen-large) {
            width: 50%;
            display: inline-block;
            vertical-align: top;

            .container.-col-2 & {
                width: 100%;
            }
        }
    }

    &.-out-wrapper {
        margin: 20rem 0 0;

        @media (--screen-medium-large) {
            margin: 25rem 0 0;

            .wrapper.-text + & {
                margin: 19rem 0 0;
            }
        }
    }

    &.-no-overflow {
        overflow: hidden;
    }
}
