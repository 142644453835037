.form-wrapper {
    display: block;
    max-width: 60rem;
    margin: 3rem auto;
    background-color: var(--color-purple-box);
    padding: 2.7rem 3.2rem 4.5rem;

    .title-3 {
        font-weight: var(--font-weight-medium);
        color: var(--color-purple);
        margin-bottom: 2.5rem;
    }

    @media (--screen-medium-large) {
        margin: 5.8rem auto 6rem;
    }

    .wrapper.-text > & {
        @media (--screen-large) {
            margin-top: 0;
        }
    }

    .wrapper.-out-wrapper > & {
        position: relative;
        top: -11rem;
        margin-bottom: -11rem;
    }
}

.form {
    display: block;
    width: 100%;
}
