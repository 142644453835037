.jumbotron {
    padding-top: 5.5rem;
}

.jumbotron__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    max-width: 62rem;

    h1 {
        margin: 0 0 1rem;
    }

    @media (--screen-large) {
        margin: 0 0 0 auto;
    }
}

.jumbotron__content__text {
    position: relative;
    color: var(--color-purple-light);
    font-size: 1.8rem;
    line-height: 1.5;
}

.jumbotron__content__anchor {
    display: none;
    width: 5rem;
    height: 5rem;
    padding-top: 13rem;
    border-left: .1rem solid var(--color-white-20);

    .button {
        transform: rotate(90deg);
    }
}

.jumbotron__image {
    img {
        @extend .image;

        // Safari only
        @media not all and (min-resolution:.001dpcm) {
            @supports (-webkit-appearance:none) {
                height: auto !important;
            }
        }
    }

    .jumbotron.-homepage & {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-gap: 1rem;
        width: calc(100% - 1.6rem);
        margin-left: 1.6rem;
    }
}

@media (--screen-medium-large) {
    .jumbotron__content h1 {
        margin: 0 0 .5rem;
    }

    .jumbotron__content__text {
        .jumbotron.-homepage & {
            font-size: 2.4rem;
        }
    }
}

@media (--screen-large) {
    .jumbotron {
        display: flex;
    }

    .jumbotron__content {
        flex-direction: row;
    }

    .jumbotron__content__text {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        height: 100%;
        max-width: 53rem;
        margin: 0;
    }

    .jumbotron__content,
    .jumbotron__image {
        flex: 0 0 calc(50%);
    }

    .jumbotron__image {
        width: 100%;
        margin-left: 0;
    }
}

@media (--screen-nav) {
    .jumbotron {
        padding-top: 16.2rem;
    }

    .jumbotron__content p {
        margin-bottom: 2.5rem;
    }

    .jumbotron__content__anchor {
        display: block;
    }
}
