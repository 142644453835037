.button-row {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 1rem 0 3.2rem;

    &.-centered {
        justify-content: center;
    }
}
