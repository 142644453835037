.testimonials {
    @extend %reset-list;
    margin-top: 4rem;

    @media (--screen-large) {
        column-count: 2;
    }
}

.testimonial {
    height: 100%;
    width: 100%;
    max-width: 60rem;
    background-color: var(--color-white);
    padding: 0 3.2rem;
    box-sizing: border-box;
    margin: 8rem auto;

    @media (--screen-large) {
        display: inline-block;
        margin: 4rem 0;
    }
}

.testimonial__inner {
    position: relative;
    top: -4rem;
}

.testimonial__top {
    text-align: center;
    margin-bottom: 2rem;
}

.testimonial__image {
    display: block;
    margin: 0 auto;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    overflow: hidden;

    img {
        @extend .image;
    }
}

.testimonial__name {
    display: block;
    font-weight: var(--font-weight-medium);
    font-size: 1.8rem;
    color: var(--color-purple);
    line-height: 1.67;
}

.testimonial__function {
    display: block;
    font-size: 1.4rem;
    color: var(--color-orange);
    line-height: 1.8;
}
