.page-footer__bottom {
    display: block;
    border-top: .1rem solid var(--color-white-10);
    padding: 1.2rem 0;
    color: var(--color-purple-medium);

    .container {
        display: flex;
        flex-wrap: wrap;

        @media (--screen-medium-large) {
            justify-content: space-between;
        }
    }
}

.page-footer__credits {
    @extend %reset-list;
    margin-right: 2rem;

    li {
        display: inline-block;
    }

    li.page-footer__credits__link {
        margin-left: 1.5rem;

        &::before {
            display: block;
            background-color: var(--color-white);
            border-radius: 50%;
            left: -1rem;
        }
    }
}
