.form-errors {
    list-style: none;
    margin: 3rem 0;
    padding: 0;
}

.form-errors__item {
    color: var(--color-red);
    font-size: 1.6rem;
    line-height: 1.56;
    margin-bottom: 1rem;
}
