.dropdown {
    @extend %reset-list;
    display: inline-block;
    background-color: var(--color-white);
    min-height: 4.5rem;
    padding: 1.2rem 1.6rem !important;
    box-sizing: border-box;

    @media (--screen-mini-nav) {
        margin-top: 2.6rem !important;
    }

    .page-header & {
        @media (--screen-mini-nav) {
            display: inline-block;
            padding: 1.2rem 1.6rem !important;
            border: .1rem solid var(--color-purple-extra-light);
            box-sizing: border-box;
        }

        display: block;
        padding: 0 !important;
        min-height: auto;
    }

    .page-head .container & {
        min-width: 23rem;

        @media (--screen-medium-large) {
            position: absolute;
            bottom: 0;
            right: 2rem;
        }
    }
}

.dropdown__trigger {
    position: relative;
    cursor: pointer;

    span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .dropdown.-open & {
            color: var(--color-orange);
        }
    }

    .page-header & {
        @media (--screen-nav) {
            border-left: .1rem solid var(--color-purple-extra-light);
            border-right: .1rem solid var(--color-purple-extra-light);
            margin: -1.2rem -1.5rem -1.4rem; // no double padding
        }
    }
}

.dropdown__panel {
    @extend %reset-list;

    position: absolute;
    left: -1.6rem;
    top: 0;
    z-index: 0;
    background: var(--color-white);
    padding: .5rem 0 .8rem !important;
    max-height: 0;
    min-width: 23rem;
    overflow: hidden;
    opacity: 0;
    transition: opacity .3s ease, max-height .3s ease;
    transform: translateY(3rem);
    box-sizing: border-box;

    & > li {
        padding: 0 !important;
    }

    &.-open {
        opacity: 1;
        z-index: 3;
        max-height: none;
        border: .1rem solid var(--color-purple-extra-light);
    }

    @media (--screen-nav) {
        left: auto;
        right: -1.6rem;
        z-index: 1;
    }

    .page-header & {
        min-width: 19rem;

        @media (--screen-nav) {
            right: 0;
            top: 1.2rem;
        }
    }
}

.dropdown__link {
    display: flex;
    align-items: center;
    padding: .8rem 1.6rem;
    text-decoration: none;
    font-size: 1.3rem;
    font-family: var(--font-general);
    font-weight: var(--font-weight-regular);
    color: var(--color-purple);
    cursor: pointer;

    &:hover,
    &:focus {
        background: var(--color-purple-extra-light);
    }
}

