h1,
.title-1,
h2,
.title-2,
h3,
.title-3,
h4,
.title-4,
h5,
.title-5,
h6,
.title-6 {
    display: block;
    margin: 1rem 0 2.4rem;
    font-weight: normal;
    line-height: 1.2;
    color: var(--color-purple);
}

h1, .title-1 {
    color: var(--color-white);
    margin: .8rem auto 3.2rem;
    font-size: 3.2rem;

    .wrapper.-highlights &,
    .wrapper.-testimonials & {
        color: var(--color-purple);
    }

    .wrapper.-testimonials &,
    .wrapper.-vacancies & {
        margin: 0 0 .8rem;
    }
}

h2, .title-2 {
    font-size: 2.4rem;

    .downloads + & {
        margin-top: 3.6rem;
    }
}

h3, .title-3 {
    color: var(--color-orange);
    font-size: 2rem;
}

h4, .title-4 {
    font-size: 1.8rem;
}

h5, .title-5 {
    font-size: 1.6rem;
}

h6, .title-6 {
    font-size: 1.5rem;
}

@media (--screen-medium-large) {
    h1, .title-1 {
        font-size: 4.6rem;
    }

    h2, .title-2 {
        font-size: 2.8rem;
    }

    h3, .title-3 {
        font-size: 2.2rem;
    }
}
