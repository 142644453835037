.block {
    margin: 0;
    padding: 0 2rem;

    .wrapper.-text &,
    &.-size-wide {
        padding: 0;
    }
}

.block__inner {
    width: 60rem;
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;

    .block.-size-wide & {
        max-width: 103.9rem;
        width: auto;
    }

    .block[data-block-type="image-with-text"] & {
        max-width: none;
    }
}
