/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white:                  #ffffff;
    --color-white-10:               rgba(255,255,255,.1);
    --color-white-20:               rgba(255,255,255,.2);
    --color-white-50:               rgba(255,255,255,.5);
    --color-black:                  #000000;
    --color-red:                    #DC3545;
    --color-red-light:              #F8D7DA;
    --color-green:                  #198754;
    --color-green-light:            #D1E7DD;

    --color-gray:                   #565B60;
    --color-gray-light:             #A1A3A6;
    --color-gray-dark:              #303030;

    --color-purple:                 #1C1A3E;
    --color-purple-80:              rgba(28,26,62,.8);
    --color-purple-hover:           #252246;
    --color-purple-extra-light:     #F5f4f9;
    --color-purple-light:           #A3A1BD;
    --color-purple-medium:          #787788;
    --color-purple-dark:            #494764;
    --color-purple-borders:         #E0E0EC;
    --color-purple-box:             #DCDCE4;
    --color-purple-images:          #3C3960;

    --color-orange:                 #FF6716;
    --color-orange-80:              rgba(255,103,22,.8);
    --color-orange-hover:           #FF7E3A;

    --font-family:                  'Montserrat', sans-serif;
    --default-text-color:           #58576A;

    --font-weight-thin:             100;
    --font-weight-extraLight:       200;
    --font-weight-light:            300;
    --font-weight-normal:           400;
    --font-weight-medium:           500;
    --font-weight-semiBold:         600;
    --font-weight-bold:             700;
    --font-weight-extraBold:        800;
    --font-weight-black:            900;
}

@custom-media --screen-nav          (width >= 1130px);

@custom-media --screen-large        (width >= 850px);

@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);

@custom-media --screen-mini-nav     (width < 1130px);
@custom-media --screen-mini-medium  (width < 850px);
@custom-media --screen-mini-small   (width < 630px);
@custom-media --screen-mini         (width < 420px);
